// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-flow-js": () => import("./../../../src/pages/flow.js" /* webpackChunkName: "component---src-pages-flow-js" */),
  "component---src-pages-gugu-zine-js": () => import("./../../../src/pages/gugu-zine.js" /* webpackChunkName: "component---src-pages-gugu-zine-js" */),
  "component---src-pages-illustration-js": () => import("./../../../src/pages/illustration.js" /* webpackChunkName: "component---src-pages-illustration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

